import React, { Component } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import HourlyButton from "../components/Buttons/HourlyButton";
import IRLayout from "../components/Layout";
import algoliasearch from "algoliasearch/lite";
import qs from "qs";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  connectStats,
  RefinementList
} from "react-instantsearch-dom";
import Masonry from "react-masonry-component";
import Container from "react-bootstrap/Container";
const updateAfter = 700;

const createURL = state => `?${qs.stringify(state)}`;
const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : "";
//const urlToSearchState = location => qs.parse(location.search.slice(1));

const HitWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const ConsultantBio = styled.h4`
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 500;
  @media (max-width: 480px) and (min-width: 320px) {
    font-size: 1em;
  }
`;
const ConsultantName = styled.h5`
  font-size: 1em;
  margin-bottom: 24px;
  @media (max-width: 480px) and (min-width: 320px) {
    font-size: 0.9em;
  }
`;

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchState: this.urlToSearchState(props.location),
      numHits: null
    };
    console.log(props.pageContext.categoryName);
  }
  urlToSearchState = function(location) {
    let myQuery = qs.parse(location.search.slice(1));
    myQuery.refinementList = {
      category: this.props.pageContext.categoryName
    };
    return myQuery;
  };
  componentWillReceiveProps(props) {
    if (props.location !== this.props.location) {
      this.setState({ searchState: this.urlToSearchState(props.location) });
    }
  }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState);

    this.debouncedSetState = setTimeout(() => {
      window.history.pushState(
        searchStateToUrl(this.props, searchState),
        searchState,
        searchStateToUrl(this.props, searchState)
      );
    }, updateAfter);
    this.setState({ searchState });
  };
  render() {
    console.log(this.urlToSearchState(this.props.location));
    const searchClient = algoliasearch(
      process.env.ALGOLIA_APP_ID,
      process.env.ALGOLIA_SEARCH_ONLY_KEY
    );

    /* setting up a blank stats widget so that we can refresh the layout when hits are returned */
    const Stats = ({ nbHits }) => {
      if (this.state.numHits !== nbHits) {
        this.setState({ numHits: nbHits }); //renders new layout if hits has updated
      }
      return null;
    };

    // 2. Connect the component using the connector
    const CustomStats = connectStats(Stats);

    const Hit = ({ hit }) => (
      <div>
        <HitWrapper>
          <Link to={`/consultants/${hit.slug}`}>
            <figure className="card" style={{ border: "0px" }}>
              <img
                className="hit_card_profile-image"
                src={`${
                  hit.cover_image.path
                }?auto=compress&width=350&height=350`}
                alt={hit.consultant_name}
              />

              <figcaption className="card__caption">
                <ConsultantBio
                  dangerouslySetInnerHTML={{
                    __html: hit.bio_snippet
                  }}
                />
                <ConsultantName>
                  <Highlight hit={hit} attribute="consultant_name">
                    {hit.consultant_name}
                  </Highlight>
                </ConsultantName>

                <HourlyButton text="View Profile" size="sm" />
              </figcaption>
            </figure>
          </Link>
        </HitWrapper>
      </div>
    );

    return (
      <IRLayout style={{ overflow: "hidden" }}>
        <Container>
          <InstantSearch
            searchClient={searchClient}
            indexName={process.env.ALGOLIA_CONSULTANT_INDEX}
            searchState={this.state.searchState}
            onSearchStateChange={this.onSearchStateChange}
            createURL={createURL}
          >
            <SearchBox
              showLoadingIndicator={true}
              translations={{
                submitTitle: "Submit your search query.",
                resetTitle: "Clear your search query.",
                placeholder:
                  "Search e.g. sales, growth, investment, agile coach..."
              }}
            />
            <h5 style={{ margin: "20px 0px" }}>
              Category: <em>{this.props.pageContext.categoryName}</em>
            </h5>
            <Masonry>
              <Hits hitComponent={Hit} />
            </Masonry>
            <CustomStats />
            <RefinementList attribute="category" className="hidden" />
          </InstantSearch>
        </Container>
      </IRLayout>
    );
  }
}

export default Search;
